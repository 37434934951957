<template>
  <div class='register  flex flex-center'>
    <!-- 补充企业资料 -->
    <div class="register_supplement">
      <div class="r_s_box p20">
        <p class="titleShow">{{ active == 2 ? "补充企业资料" : "请选择您想使用的应用" }}</p>
        <div class='flex flex-center' v-if=" active==2">
          <el-form
              ref='searchForm'
              style='width: 90%'
              :rules='rules'
              :model='formInline'
              class='mt-4'
              label-width='150'
          >
            <el-row :gutter='50'>
              <el-col :span='12'>

                <el-form-item label='组织名称：' prop='cnName'>
                  <el-input
                      v-model='formInline.cnName'
                      maxlength='60'
                      show-word-limit
                      placeholder='请输入'
                  />
                </el-form-item>
              </el-col>
              <el-col :span='12'>

                <el-form-item label='组织简称：' prop='sname'>
                  <el-input
                      v-model='formInline.sname'
                      maxlength='20'
                      show-word-limit
                      placeholder='请输入'
                  />
                </el-form-item>

              </el-col>
              <el-col :span='12'>
                <el-form-item label='英文缩写：' prop='senName'>
                  <el-input
                      v-model='formInline.senName'
                      maxlength='10'
                      show-word-limit
                      placeholder='请输入'
                  />
                </el-form-item>
              </el-col>
              <el-col :span='12'>
                <el-form-item label='统一社会信用代码：' prop='orgCode'>
                  <el-input
                      v-model='formInline.orgCode'
                      placeholder='请输入'
                  />
                </el-form-item>
              </el-col>
              <el-col :span='12'>
                <el-form-item label='行政区划：' prop='areaArr'>
                  <el-cascader
                      v-model='formInline.areaArr'
                      style='width: 100%;'
                      :options='cityData'
                      placeholder="请选择行政区域"
                      :props='{label:"value"}'
                      class='mr-2 noBorder'
                  />
                </el-form-item>
              </el-col>
              <el-col :span='12'>
                <el-form-item label='通信地址：' prop='addr'>
                  <el-input
                      v-model='formInline.addr'
                      type='textarea'
                      maxlength='100'
                      show-word-limit
                      :rows='4'
                      placeholder='请输入'
                  />
                </el-form-item>
              </el-col>
              <el-col :span='12'>
                <el-form-item label='备注：' prop='describe'>
                  <el-input
                      v-model='formInline.describe'
                      type='textarea'
                      maxlength='200'
                      show-word-limit
                      :rows='4'
                      placeholder='请输入'
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <div class='register-type1 flex f_c_e '>
              <!--              <div class='form-box'>-->
              <div class="flex f_c_e">
                <p type="text" class="btn_p cursor-pointer"  style="width: 100px" @click='cancelAdd'>
                  取消</p>
                <el-button type='primary' class="btn" @click='next'>
                  下一步,选择应用
                </el-button>
              </div>
              <!--              </div>-->
            </div>
          </el-form>
        </div>
        <div class='p20' v-if=" active==3">
          <el-form
              ref='searchForm'
              style='width: 90%'
              :rules='rules'
              :model='formInline'
              class='mt-4'
              label-width='120'
          >
            <el-row>
              <el-col :span='12'>
                <el-form-item label='选择企业类型：' prop='divisionDivision'>
                  <el-select
                      v-model='formInline.markCode'
                      style='width:300px;'
                      placeholder="选择企业类型"
                      class='mr-2 noBorder'
                      @change="selectAppByFeatureApi"
                  >
                    <el-option v-for="item in selectData" :value='item.id' :label='item.name'/>
                  </el-select>
                </el-form-item>
              </el-col>

            </el-row>

          </el-form>
          <el-checkbox-group v-model="checkList">
            <el-empty description="暂无可选择的应用" v-if="!tenantList.length"/>
            <div class="cardBox flex" v-if="tenantList.length">
              <div v-for="(item,index) in tenantList" class="w50">
                <div class="box-card card flex" @click="chooseApp(item.tempAppId)">
                  <el-checkbox :label="item.tempAppId" size="large" class="check-box-btn"/>
                  <div class="flex column f_c_c mr-4">
                    <img v-if='item.path' :src='item.path' alt=''>
                    <img v-if='!item.path' :src='baseImg' alt='' class=''>
                  </div>
                  <div class="flex_list flex column ">
                    <p class='name' :title="item.cnName">{{ item.cnName }}</p>
                    <p class='p2'>{{ item.descr }}</p>
                  </div>
                </div>

              </div>
            </div>
          </el-checkbox-group>
          <div class='register-type1 flex f_c_e '>
            <div class="flex f_c_e">
              <p type="text" class="btn_p cursor-pointer " v-if="number===1" style="width: 100px" @click='cancelAdd'>
                取消</p>
              <p type="text" class="btn_p cursor-pointer" style="width: 100px" @click='active=2'>
                上一步</p>
              <el-button type='primary' class="btn ml-1" @click='saveApply'>
                完成
              </el-button>
            </div>
            <!--              </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {checkUsername, getCodeApi, isUserExist, register, registerOutPre, registerOut} from "@/api/UserInfo";
import JSEncrypt from "jsencrypt";
import {ElMessage} from "element-plus";
import getData from '@/assets/js/city1.json'

const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCk2nvbv1V92I7wodOVGhAezEHrRHsPj3XQRzLEUYzhkXSqTAVDJrC3fS8c8bz2NDIROr5XzOmgTVrDIFL/kYSjc79Q+65684pYMN2R+pZKplB8hlUhJ5LscrES43TquLM6Oycp98D64tqtGm/XIURoi4b9iDQpVFNFqk3O1K919wIDAQAB';
const encrypt = new JSEncrypt();
encrypt.setPublicKey(publicKey);
import baseImg from '@/assets/img/APPC.png'
import {addOrgApp, addOrgUser, getAllFeatures, selectAppByFeature} from "@/api";
import {saveApplyApi} from "@/api/announcementView";

export default {
  components: {},
  data() {
    return {
      number: 1,
      cityData: getData,
      baseImg,
      selectData: [],
      formInline: {
        markCode: null
      },
      checkList: [],
      tenantList: [],
      sendShort: '',
      totalTime: '10',
      canClick: true, // 控制是否可以点击
      dialogVisible: false,
      active: 2,
      btnText: "获取验证码",
      time: 60,
      ruleForm: {
        username: '',
        password: '',
        phone: '',
        sms: ''
      },
      /* form 表单规则 */
      rules: {
        cnName: [
          {required: true, message: '请输入名称', trigger: 'blur'}
        ],
        senName: [
          {required: true, message: '请输入英文名称', trigger: 'blur'},
          // { pattern: /^[A-Za-z]+$/i, message: '只能输入英文名称', trigger: 'blur' }
        ],
        appId: [
          {required: false, message: '请输入appId', trigger: 'blur'},
          {pattern: /^[a-zA-Z0-9]+$/, message: 'appid只能包含大小写英文字母或数字', trigger: ['change', 'blur']}
        ],
        orgCode: [
          {required: true, message: '请输入社会统一信用代码'}
          // {
          //     validator: (rule, value, callback) => {
          //         if (value === '') {
          //             callback()
          //         }
          //         // else if (/^[A-Z0-9]{18}$/.test(value)) {
          //         //     callback()
          //         // }
          //         else {
          //             callback(new Error('请输入正确的社会统一信用代码'))
          //         }
          //     }
          // }
        ],
        phoneNo: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          {pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号码', trigger: 'blur'}
        ],
        sname: [
          {required: true, message: '请输入组织简称', trigger: 'blur'}
        ],
        contacts: [
          {required: true, message: '请输入联系人', trigger: 'blur'}
        ], addr: [
          {required: true, message: '请输入通讯地址', trigger: 'blur'}
        ],
        areaArr: [
          {required: true, message: '请输入通讯地址', trigger: 'change'}
        ],


        idCardNo: [
          {required: false, message: '请输入正确的身份证号', trigger: 'blur'},
          {pattern: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入正确的身份证号', trigger: 'blur'}
        ],
        email: [
          {required: false, message: '请输入正确的邮箱', trigger: 'blur'},
          {
            pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/,
            message: '请输入正确的邮箱',
            trigger: 'blur'
          }
        ]

      }
    }
  },
  watch: {
    $route(to, from) {
      this.active = to.query.active || "2"
    },
  },
  mounted() {
    this.active = this.$route.query.active || "2"
    this.getAllFeaturesApi()
  },
  methods: {
    //保存
    saveApply() {
      addOrgApp({appIds: this.checkList, username: sessionStorage.getItem("loginUserName")}).then(result => {
        ElMessage.success("添加成功")
        /* 有两种情况， 一种是注册后 直接跳到登录  一种是 登录后已经登录了  */
        // if()
        this.goLogin()
      })
    },
    /* 取消编辑组织信息
    *  直接跳到登录页
    *  */
    cancelAdd() {
      this.$emit("loginOut")
    },
    chooseApp(index) {
      if (this.checkList.includes(index)) {
        this.checkList = this.checkList.filter(item => {
          return item !== index
        })
      } else {
        this.checkList.push(index)
      }
    },
    /* 点开下一步选择应用 */
    next() {
      this.$refs.searchForm.validate((valid) => {
        if (valid) {
          this.addOrgUserApi()
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    addOrgUserApi() {
      this.formInline.username = sessionStorage.getItem("loginUserName")
      this.formInline.area = this.formInline.areaArr.join("-")
      addOrgUser(this.formInline).then(result => {
        ElMessage.success("添加成功")
        /* 判断是否有token */
        if (sessionStorage.getItem("token")) {
          console.log("需要刷新组织信息")
          // 需要刷新组织信息
          //this.$emit("orgCodefun", this.formInline.orgCode)
        }
        this.number++
        this.active = 3
      })
    },
    //查询所有二级标志特点
    getAllFeaturesApi() {
      getAllFeatures().then(result => {
        this.selectData = result.data
      })
    },
    //
    selectAppByFeatureApi() {
      this.checkList = []
      selectAppByFeature(this.formInline.markCode).then(result => {
        this.tenantList = result.data
      })
    },
    // 返回
    goback() {
      this.active = 0
      // this.ruleForm
    },
    goLogin() {
      this.$router.push('/')
    },
    closeMask() {
      this.dialogVisible = false
      // window.clearInterval(this.cloak);
      // this.totalTime='10';
    },
  }
}
</script>

<style lang="less" scoped>
.register {
  height: 100vh;
  width: 100%;
  height: 100vh;
 // background-image: url('../../assets/img/User/register.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  .titleShow {
    display: inline-block;
    font-size: 20px;
    color: var(--el-color-primary);
    font-weight: bold;
    position: relative;
    line-height: 50px;
    border-bottom: 2px solid var(--el-color-primary);
  }

  .cardBox {
    width: 100%;
    flex-wrap: wrap;
    padding: 20px;

    .w50 {
      width: 40%;
      margin-right: 40px;
    }

    .check-box-btn {
      position: absolute;
      top: 0;
      right: 20px;
    }

  }

  ::v-deep(.el-checkbox__label) {
    display: none;
  }

  .box-card {
    width: 100%;
    height: 120px;
    font-size: 30px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: flex-start;
    margin-right: 40px;
    margin-bottom: 20px;
    border: 1px solid #e4e7ed;
    padding-left: 20px;
    position: relative;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);

    img {
      height: auto;
      width: 50px;
    }

    .flex_list {
      width: calc(100% - 70px);
      height: calc(100% - 20px);
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        font-size: 14px;
        line-height: 30px;
      }

      .p2 {
        word-break: break-word;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 将显示的行数设置为 2 行 */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }

      .name {
        width: 100%;

      }
    }
  }

  .register_supplement {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 2;
    padding: 20px;

    .r_s_box {
      height: 100%;
      width: 100%;
      border-radius: 4px;
      border: 1px solid #e4e7ed;
      background-color: #fff;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, .12);
      overflow: hidden;
      transition: var(--el-transition-duration);
    }
  }

  .register_content {
    width: 800px;
    height: 600px;
    background: #FFFFFF;
    box-shadow: 0px 0px 18px 2px rgba(64, 147, 250, 0.1);
    border-radius: 4px;

    .title {
      text-align: center;
      position: relative;
      line-height: 50px;
      margin: 80px auto;
      font-size: 34px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #000000;
    }

    .title::after {
      content: ' ';
      position: absolute;
      height: 2px;
      width: 64px;
      bottom: 0;
      background: #1890FF;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .steps-box {
      width: 40%;
      margin: 0 auto;

      .stepIcon {
        width: 40px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 20px;
        text-align: center;
        line-height: 40px;
        border: 1px solid #919191;
        color: #919191;
      }

      .active {
        border: 1px solid #3381FF;
        color: #3381FF;
      }

      ::v-deep(.el-step__line) {
        height: 1px;
        position: absolute;
        border-color: inherit;
        background-color: transparent;
        border: 1px dashed var(--el-text-color-placeholder);
      }

      .el-step.is-simple:not(:last-of-type) .el-step__title {
        max-width: none;
      }
    }
  }

  .itemForm {
    height: 50px;
    box-sizing: border-box;
    margin: 0 auto 40px;
    border: 1px solid #DCDCDC;
    border-radius: 4px;

    .el-input {
      width: 100%;
      height: 100%;
    }

    ::v-deep(.el-input__inner) {
      width: 100%;
      height: 100%;
      border: none !important;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 300;
      color: #333333 !important;
    }

    ::v-deep(.el-input__wrapper) {
      box-shadow: none;
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  .code {
    position: relative;
  }

  .getCode {
    position: absolute;
    right: 30px;
  }

  .btn_p {
    position: relative;
    font-size: 14px;
    text-align: center;
  }

  .btn_p::after {
    content: ' ';
    position: absolute;
    height: 2px;
    width: 30px;
    bottom: 0;
    background: #333333;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .btn {
    width: 100%;
    height: 44px;
    background: #1890FF;
    border-radius: 4px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 33px;
    margin: 0 auto 0;
    text-align: center;
    display: flex;
    justify-content: center;
  }





  .prefixUserRight {
    width: 1px;
    height: 34px;
    background: #DCDCDC;
    margin-right: 16px;
  }

  .register-title {
    width: 1000px;
    height: 98px;
    background: #ffffff;
    line-height: 98px;
    margin: 0 auto;
    display: flex;

    .logo-item {
      width: 137px;
      height: 34px;
      //background: url("./images/zhucetitle.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 32px;
    }

    .world-item {
      width: 96px;
      height: 33px;
      line-height: 33px;
      text-align: center;
      margin-top: 33px;
      margin-left: 20px;
      color: #05B37B;
      border-left: 2px solid #E9E9E9;
    }

    .login-item {
      margin-left: 615px;
      font-size: 14px;
      color: #555555;

      em {
        color: #05B37B;
        cursor: pointer;
      }
    }
  }

  .register-image {
    height: 182px;
    line-height: 182px;
    text-align: center;
    font-size: 40px;
    color: #ffffff;
    width: 100%;
    /*background: #00CB8A;*/
    //background: url('./images/navbar.png') no-repeat;
    background-size: 100% 100%;
  }

  .register-type1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    bottom: 130px;
    right:5%;

    .btn_p {
      position: relative;
      font-size: 14px;
      text-align: center;
      margin-top: 10px;
    }

  }

  .register-type {
    width: 50%;
    margin: 0 auto;
    margin-top: 50px;
    /*display: flex;*/
    /*flex-direction: row;*/
    /*justify-content: space-between;*/

    img {
      display: block;
      width: 148px;
      height: 124px;
      /*border: 1px solid #33aef0;*/
      margin: 0 auto;
    }

    p {
      width: 100%;
      text-align: center;
      font-size: 18px;
      color: #333333;
      margin-top: 14px;
    }

    .agent {
      width: 290px;
      height: 312px;

      .el-button {
        width: 290px;
        height: 70px;
        background: #00cb8a;
      }

      .el-button--primary {
        border-color: transparent;
      }

      .agent-img {
        padding-top: 40px;
        width: 100%;
        height: 242px;
        background: white;
      }
    }

    .shipper:hover {
      transform: translate(0, -20px);
      transition: 1.5s;
      -webkit-transform: translate(0, -20px);
      -moz-transform: translate(0, -20px);
      -o-transform: translate(0, -20px);
      -ms-transform: translate(0, -20px);
    }

    .agent:hover {
      transform: translate(0, -20px);
      transition: 1.5s;
      -webkit-transform: translate(0, -20px);
      -moz-transform: translate(0, -20px);
      -o-transform: translate(0, -20px);
      -ms-transform: translate(0, -20px);
    }
  }

  .register-container {
    padding: 30px;
    width: 1000px;
    height: 510px;
    margin: 0 auto;


    .next-box {
      width: 400px;
      bottom: 20px;
      margin: 0 auto;

      .el-button {
        width: 100%;
        background: #00cb8a;
      }

      .el-button--primary {
        border-color: transparent;
      }
    }
  }

  .agreement {
    user-select: none;
    width: 100%;
    height: 500px;
    overflow: auto;
  }
}
</style>
