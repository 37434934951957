import { Ref, unref } from 'vue'


/**
 * sessionStorage设置有效期
 * @param name sessionStorage设置名称
 * @param data 数据对象
 * @param pExpires 有效期(默认100年)
 */
export function setLocal(name, data) {
    sessionStorage.setItem(name, JSON.stringify(data))
}

/**
 * 表单校验
 * @param ref 节点
 * @param isGetError 是否获取错误项
 */
export async function validate(ref, isGetError = false) {
    const validateFn = unref(ref).validate
    return new Promise(resolve => validateFn((valid, object) => isGetError ? resolve({ valid, object }) : resolve(valid)))
}

/**
 * 对部分表单字段进行校验的方法
 * @param ref 节点
 * @param props 字段属性
 */
export async function validateField(ref, props) {
    const validateFieldFn = unref(ref).validateField
    return new Promise(resolve => validateFieldFn(props, (errorMessage) => resolve(errorMessage)))
}

/**
 * 重置表单
 * @param ref 节点
 */
export function resetFields(ref) {
    const resetFieldsFn = unref(ref).resetFields
    resetFieldsFn()
}

/**
 * 移除表单项的校验结果
 * @param ref 节点
 * @param props 字段属性
 */
export function clearValidate(ref, props) {
    const clearValidateFn = unref(ref).clearValidate
    props ? clearValidateFn(props) : clearValidateFn()
}
