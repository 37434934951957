<template>
  <div>
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="80px"
             label-position="top">
      <OutlinedInput class="m_r_10" v-model:value="ruleForm.oldPassword" placeholder="旧密码" :isBorder="true"
                     :inputHeight="40" show-password :maxlength="20"
                     :typeInput="'password'"></OutlinedInput>
      <OutlinedInput class="m_r_10" v-model:value="ruleForm.newPassword" show-password placeholder="新密码"
                     :isBorder="true" :maxlength="20"
                     :inputHeight="40"
                     @input="changePasswordInput"
                     :typeInput="'password'"></OutlinedInput>
      <div class="flex items-center" v-if="strength">
        <span class="flex-shrink-0">密码强度：</span>
        <div
            class="bar"
            :style="{ background: barColor, width: width + '%' }">
          <!-- 展示文字 -->
          <div
              class="strength text-center"
              :style="{ color:'#fff'}">
            {{ strength }}
          </div>
        </div>
      </div>

      <OutlinedInput class="m_r_10" v-model:value="ruleForm.secondNewPassword" show-password placeholder="确认密码"
                     :isBorder="true" :maxlength="20"
                     :inputHeight="40"
                     :typeInput="'password'"></OutlinedInput>
      <div class="form-reset-submit flex justify-end">
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button style="margin-right: 20px" @click="reset">关闭</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import {checkPassword} from '@/utils/common'
// import { resetPassword } from '@/api/UserInfo'
import {changePassword} from "@/api/UserInfo";
import JSEncrypt from "jsencrypt";
import actions from "@/action";
import OutlinedInput from "@/components/OutlinedInput.vue";
import {level} from "@/components/passwordStrength/CheckPassword";
import {ElMessage} from "element-plus";

export default {
  props: {
    userId: {
      type: String,
      default: ''
    },
  },
  data() { // 初始化数据
    //18888888888@8888!
    return {
      strength: '',
      sendShort: '',
      barColor: '',
      width: '',
      ruleForm: {
        oldPassword: '',
        newPassword: '',
        secondNewPassword: '',
      },
      rules: {
        oldPassword: [
          {required: true, message: '旧密码不能为空', trigger: 'blur'},
        ],
        newPassword: [
          {required: true, message: '请输入新密码', trigger: 'blur'},
          {validator: this.validateNewPassword, trigger: 'blur'},
          {
            pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,.\/]).{8,24}$/,
            message: '密码包含字母数字特殊字符长度8到24位',
            trigger: 'change'
          },
        ],
        secondNewPassword: [
          {required: true, message: '请输入确认密码', trigger: 'blur'},
          {validator: this.validateConfirmPassword, trigger: 'blur'},
          {
            pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,.\/]).{8,24}$/,
            message: '密码包含字母数字特殊字符长度8到24位',
            trigger: 'change'
          },
        ]
      }
    }
  },
  methods: {
    changePasswordInput(row) {
      if (row) {
        if (row.target) {
          let v = row.target.value
          if (v != '') {
            const res = level(v);
            if (res === '非常弱') {
              this.barColor = 'red';
              this.width = 20;
              this.strength = '非常弱';
            } else if (res === '弱') {
              this.barColor = '#ee795c';
              this.width = 30;
              this.strength = '弱';
            } else if (res === '一般') {
              this.barColor = 'orange';
              this.width = 50;
              this.strength = '中';
            } else if (res === '强') {
              this.barColor = 'green';
              this.width = 70;
              this.strength = '强';
            } else if (res === '非常强') {
              this.barColor = '#1B8EF8';
              this.width = 80;
              this.strength = '非常强';
            }
          } else {
            this.barColor = '';
            this.width = 0;
            this.strength = '';
          }
        }
      }
    },
    // 定义函数
    validateOldPassword(rule, value, callback) {
      if (!value) return callback(new Error('密码不能为空'))
      return callback()
    },
    validateNewPassword(rule, value, callback) {
      if (!value) return callback(new Error('密码不能为空'))
      // if (!checkPassword(value)) return callback(new Error('密码长度为8-16位且必须包含大小写字母、数字和特殊字符串'))
      if (this.ruleForm.newPassword !== this.ruleForm.secondNewPassword) return callback(new Error('新密码与确认密码不相同'))
      return callback()
    },
    validateConfirmPassword(rule, value, callback) {
      if (!value) return callback(new Error('密码不能为空'))
      // if (!checkPassword(value)) return callback(new Error('密码长度为8-16位且必须包含大小写字母、数字和特殊字符串'))
      if (this.ruleForm.newPassword !== this.ruleForm.secondNewPassword) return callback(new Error('新密码与确认密码不相同'))
      this.$refs.ruleForm.validateField('newPassword')
      return callback()
    },
    reset() {
      this.$refs.ruleForm.resetFields()
      this.$emit("closePassword")

    },
    onSubmit() {
      if (!this.ruleForm.oldPassword) {
        this.$message.error("旧密码不能为空")
        return
      }
      if (!this.ruleForm.newPassword) {
        this.$message.error("新密码不能为空")
        return
      }
      if (!this.ruleForm.secondNewPassword) {
        this.$message.error("确认密码不能为空")
        return
      }
      if (!this.strength || this.strength == '弱' || this.strength == '非常弱') {
        ElMessage.error("密码安全等级不足,请重新输入")
        return
      }
      if (this.ruleForm.newPassword !== this.ruleForm.secondNewPassword) {
        ElMessage.error("两次密码不一致，请重新输入")
        return
      }
      this.$refs.ruleForm.validate((result, object) => {
        if (result) {
          const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCk2nvbv1V92I7wodOVGhAezEHrRHsPj3XQRzLEUYzhkXSqTAVDJrC3fS8c8bz2NDIROr5XzOmgTVrDIFL/kYSjc79Q+65684pYMN2R+pZKplB8hlUhJ5LscrES43TquLM6Oycp98D64tqtGm/XIURoi4b9iDQpVFNFqk3O1K919wIDAQAB';
          const encrypt = new JSEncrypt();
          encrypt.setPublicKey(publicKey);
          changePassword({
            id: this.userId,
            oldPassword: encrypt.encrypt(this.ruleForm.oldPassword),
            newPassword: encrypt.encrypt(this.ruleForm.newPassword),
            secondNewPassword: encrypt.encrypt(this.ruleForm.secondNewPassword)
          }).then(res => {
            if (res.code === 200) {
              this.reset()
              this.$message({
                type: 'success',
                message: '修改密码成功，请重新登录',
                duration: 1000,
                onClose: () => {
                  // sessionStorage.clear();
                  // 退出登录
                  actions.setGlobalState({noToken: true, activePath: '/'});
                  this.$router.push({path: "/"});
                }
              })
            } else {
              this.$message({type: 'error', message: '修改密码失败'})
            }
          }, error => {
            // this.$message({ type: 'error', message: '修改密码失败' })
          })
        }
      })
    }
  },
  components: {
    OutlinedInput // 解构映射到组件

  }
}
/*
data() { // 初始化数据
  return {}
},
methods: { // 定义函数

},
components: { // 解构映射到组件

}
beforeCreate () { // 创建前状态

},
created () { // 创建完毕状态

},
beforeMount () { // 初始化完成前状态

},
mounted() { //初始化完成后的回调函数

},
beforeUpdate() { // 更新前状态

},
updated() { // 更新完成状态

},
beforeDestroy() { // 销毁前状态

},
destroyed() { // 销毁完成状态

},
computed: { // 计算属性

},
watch: { // 深度监视，数据一旦发生改变，立马更新保存数据

},
*/
</script>
<style scoped lang='less'>
.el-form-item {
  margin-bottom: 32px;
}
</style>
