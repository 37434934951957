<template>
  <div>
    <el-form class="w-full" :model="ruleForm" status-icon inline :rules="rules" ref="ruleForm"
             label-position="top">
      <el-form-item label='' prop='cnName'>
        <OutlinedInput class="m_r_10" v-model:value="ruleForm.cnName" placeholder="用户姓名" :isBorder="true"
                       :inputHeight="40"
                       :maxlength="10"
                       show-word-limit
                       :typeInput="'text'"></OutlinedInput>
      </el-form-item>
      <el-form-item label='' prop='email'>
        <OutlinedInput class="m_l_10" v-model:value="ruleForm.email" placeholder="邮箱" show-word-limit :maxlength="60"
                       :isBorder="true" :inputHeight="40"
                       :typeInput="'text'"></OutlinedInput>
        <!--      <OutlinedInput class="m_l_10" v-model:value="ruleForm.userName" placeholder="用户名" :isBorder="true"-->
        <!--                     :inputHeight="40"-->
        <!--                     :typeInput="'text'"></OutlinedInput>-->
      </el-form-item>
      <el-form-item label='' prop='phone'>
        <OutlinedInput class="m_r_10" v-model:value="ruleForm.phone" :disabled="true" placeholder="手机号"
                       :isBorder="true"
                       :inputHeight="40"
                       :maxlength="11"
                       :typeInput="'number'"></OutlinedInput>
      </el-form-item>
      <el-form-item label='' prop=''>
        <defaultInput class="m_l_10  w-50" :placeholder="ruleForm.authFlag=='0'?'未认证':'已认证'" :isBorder="true" :inputHeight="40"
                      :typeInput="'text'">
          <span class="m_l_20 cursor-pointer primary" v-if="ruleForm.authFlag=='0'" @click="auth">未认证，点击认证</span>
          <span class="m_l_20 cursor-pointer primary" v-if="ruleForm.authFlag=='1'"
                @click="changePhone">更换手机号</span>
        </defaultInput>
      </el-form-item>
      <el-form-item label='' prop='addr'>
        <OutlinedInput class="m_r_10" v-model:value="ruleForm.addr" :maxlength="100" placeholder="详细地址"
                       :isBorder="true"
                       show-word-limit
                       :inputHeight="40"
                       :typeInput="'text'"></OutlinedInput>
      </el-form-item>
      <el-form-item label='' prop=''>
        <OutlinedCascader
            :options="cityData"
            v-model:value="ruleForm.areaArr"
            placeholder="行政区划"
            :show-all-levels="true"
            :isBorder="true"
            :clearable='true'
            :propsValue='{label:"value"}'
            :inputHeight="40"
            class="m_l_10 m_l_10"
        >
        </OutlinedCascader>
      </el-form-item>
      <el-form-item label='' prop='idCardNo'>
        <OutlinedInput class="m_r_10" v-model:value="ruleForm.idCardNo" placeholder="身份证号" :isBorder="true"
                       show-word-limit
                       :inputHeight="40" :maxlength="18"
                       :typeInput="'text'"></OutlinedInput>
      </el-form-item>
      <el-form-item label="头像：" prop="name" class="m_l_10">
        <upload :isImg="true" :multiple="false" :autoUpload="true" :limit="1" :format="['image/*']"
                v-model:value="ruleForm.photo" v-model:photoTemp="ruleForm.photoTemp">
          <div class="flex flex-col items-center">
            <svg-icon icon-class="uplod_img" class-name="file_icon" style="margin-top: 10px"></svg-icon>
            <p class="upload_text">选择文件</p>
          </div>

        </upload>
      </el-form-item>

      <div class="form-reset-submit">
        <el-button style="margin-right: 20px" @click="changePasswordShow">修改密码</el-button>
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </div>
    </el-form>
    <el-dialog v-model="dialogTableVisible" title="修改密码" width="400" destroy-on-close>
      <editPassword :userId='ruleForm.id' @closePassword="dialogTableVisible=false"></editPassword>
    </el-dialog>
    <el-dialog v-model="dialogVisible" title="认证手机号" width="400" destroy-on-close>
      <changePhone ref="changePhoneRef1" :isabled="true" :userId='ruleForm.id' @closePassword="authPhoneFun"></changePhone>
    </el-dialog>
    <el-dialog v-model="dialogPhoneVisible" title="更换手机号" width="400" destroy-on-close>
      <changePhone :userId='ruleForm.id'  :ischange="true" @closePassword="dialogPhoneVisible=false"
                   @submitPassword="changePhoneFun"></changePhone>
    </el-dialog>

  </div>
</template>
<script>
import {checkPhone, checkEmail} from '@/utils/common'
import editPassword from '@/views/User/editPersonalInfo/component/editPassword.vue'
import changePhone from '@/views/User/editPersonalInfo/component/changePhone.vue'
import getData from '@/assets/js/city.json'
import {updatePersonUserInfo} from "@/api/UserInfo";
import OutlinedInput from '@/components/OutlinedInput.vue'
import OutlinedCascader from '@/components/OutlinedCascader.vue'
import defaultInput from '@/components/defaultInput.vue'
import upload from "@/components/Upload/index.vue";
import {getUserInfo} from "@/api";
import {mapMutations} from "vuex";

export default {
  data() { // 初始化数据
    return {
      cityData: '',
      dialogTableVisible: false,
      dialogPhoneVisible: false,
      dialogVisible: false,
      ruleForm: {
        authFlag: '',
        areaArr: [],
        photoTemp: ''
      },
      rules: {
        cnName: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          {pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号码', trigger: 'blur'}
        ],
        idCardNo: [
          {required: false, message: '请输入正确的身份证号', trigger: 'blur'},
          {
            pattern: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: '请输入正确的身份证号',
            trigger: 'blur'
          }
        ],
        email: [
          {required: false, message: '请输入正确的邮箱', trigger: 'blur'},
          {
            pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-]+)+$/,
            message: '请输入正确的邮箱',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations(['SET_USERMANAGE', "SET_NOICENUMBER"]),
    // 认证手机
    auth() {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.changePhoneRef1?.init(this.ruleForm.phone)
      })
    },
    // 更换手机号
    changePhone() {
      this.dialogPhoneVisible = true
      this.$nextTick(() => {
        this.$refs.changePhoneRef?.init(this.ruleForm.phone)
      })
    },
    // 修改密码
    changePasswordShow() {
      this.dialogTableVisible = true
    },
    getInfo(isUp = false) {
      getUserInfo().then(res => {
        if (res.code === 200) {
          this.ruleForm = res.data.sysUser
          if (isUp) {
            this.SET_USERMANAGE(res.data.sysUser);
          }
          this.ruleForm.areaArr = this.ruleForm.area ? this.ruleForm.area.split("-") : []
        }
      })
    },
    reset() {
      this.$refs.ruleForm.resetFields()
    },
    // 关闭个人中心
    close() {
      this.$refs.ruleForm.resetFields()
      let path = sessionStorage.getItem('userPath')
      this.$router.replace(path)
    },
    // 更换手机号
    async changePhoneFun(phone) {
      this.dialogPhoneVisible = false
      // 获取用户信息
      this.getInfo()
      await this.$message({
        type: 'success',
        message: '手机号更换成功！',
        duration: 1000,
      })
    },
    // 认证信息
    authPhoneFun(phone) {
      this.dialogVisible = false
      // 获取用户信息
      this.getInfo()
    },
    onSubmit() {
      this.$refs.ruleForm.validate((result, object) => {
        if (result) {
          let params = {
            "addr": this.ruleForm.addr,
            "cnName": this.ruleForm.cnName,
            "userName": this.ruleForm.userName,
            "email": this.ruleForm.email,
            "id": this.ruleForm.id,
            "idCardNo": this.ruleForm.idCardNo,
            "phone": this.ruleForm.phone,
            "photo": this.ruleForm.photo
          }
          params.area = this.ruleForm.areaArr ? this.ruleForm.areaArr.join('-') : ''
          updatePersonUserInfo(params).then(res => {
            if (res.code === 200) {
              this.reset()
              this.$message({
                type: 'success',
                message: '修改基本资料成功',
                duration: 1000,
              })
              this.getInfo(true)
            } else {
              this.$message({type: 'error', message: '修改基本资料失败'})
            }
          }, error => {
          })
        }
      })
    }
  },
  components: {
    OutlinedInput, upload, OutlinedCascader, editPassword, defaultInput, changePhone // 解构映射到组件
  },
  created() {
    this.cityData = getData
    // 获取用户信息
    this.getInfo()
  }
}
</script>
<style scoped lang='less'>
.form-reset-submit {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

::v-deep(.el-form-item) {
  width: calc(50% - 10px);

  .el-form-item__error {
    top: calc(100% - 20px) !important;
  }

  .custom-input-container {
    width: 100%;
  }
}

::v-deep(.el-form--inline .el-form-item) {
  margin-right: 0px !important;
}

//.custom-input-container {
//  width: calc(50% - 10px);
//}

::v-deep(.el-form-item__content) {
  width: 100%;
}

.form-reset-submit {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-form-item__label {
  color: var(--text-color);
}

.file_icon {
  width: 30px;
  height: 23px;
  color: var(--el-color-primary);
}

.primary {
  color: var(--el-color-primary);
}

::v-deep(.el-upload--picture-card) {
  height: 90px;
  width: 90px;
}

.upload_text {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
}
</style>
