<template>
  <div>
    <el-upload :action="action" v-model:file-list="fileList" :headers='headers' :data="{ img: isImg }"
               :list-type="isImg ? 'picture-card' : 'text'" :multiple="multiple" :show-file-list="showFileList"
               :before-upload="beforeUpload" :auto-upload="autoUpload" :limit="limit" :on-success="changeFile"
               :on-error="errorList" :accept="acceptValue" :on-change="fileGet" :on-exceed="fileExceed" :on-remove="fileRemove">
      <!-- <el-button type="primary" v-if="!isImg">文件上传</el-button> -->
      <div v-if="!isImg" class="flex flex-col justify-center items-center file-style">
        <slot></slot>
        <p>选择文件</p>
      </div>
      <template #tip v-if="!isImg && showTip">
        <div class="el-upload__tip">
          {{ formatValue }}
        </div>
      </template>
      <slot></slot>
    </el-upload>
  </div>
</template>
<script >
import { defineComponent, PropType, ref, nextTick, watch, computed, toRefs } from 'vue'

import { Message, Plus } from '@element-plus/icons-vue'
import { ElMessage, UploadFile } from 'element-plus';

export default defineComponent({
  name: "uploadCom",
  components: {
    Plus
  },
  emits: ['update:value'], // 声明自定义事件
  props: {
    // 图片还是文件·
    isImg: {
      type: Boolean,
      default: true
    },
    // 是否允许多选
    multiple: {
      type: Boolean,
      default: false
    },
    // 允许上传文件的最大数量
    limit: {
      default: 20
    },
    // 单个文件最大M
    size: {
      default: 50
    },
    // 是否展示文件列表
    showFileList: {
      type: Boolean,
      default: false
    },
    // 格式
    format: {
      type: Array,
      default: []
    },
    // 是否自动上传文件
    autoUpload: {
      type: Boolean,
      default: true
    },
    // 是否显示提示语
    showTip: {
      type: Boolean,
      default: true
    },
    // url地址
    action: {
      type: String,
      default: '/fire/api/file/upload'
    },
    // 列表
    value: {
      type: [String, Array],
      default: () => []
    }
  },
  setup(props, { emit }) {
    const fileList = ref([])
    const headers = { Authorization: JSON.parse(sessionStorage.getItem('token') ), Mark: 'Mark' }
    // 格式计算
    const formatValue = ref('')
    const acceptValue = computed(() => {
      let lastStr= ''
      let acceptStr = ''
      if (props.format.length == 0) {
        lastStr = ''
        acceptStr = null
      } else {
        let str= ''
        acceptStr = ''
        props.format.map((item, index) => {
          str += index == 0 ? item : '、' + item
          if (item == 'image/*') {
            acceptStr += item
          } else {
            acceptStr += (index == 0 ? '.' + item : ',.' + item)
          }

        })

        lastStr = '请上传' + str + '格式的文件'
      }
      lastStr = lastStr == '' ? '文件不得超过' + props.size + 'M' : lastStr + '且单个文件不得超过' + props.size + 'M'
      formatValue.value = lastStr
      return acceptStr
    })
    // nextTick(() =>{
    //     fileList.value = props.fileListProps
    // })
    // 监听 props 中的 value 变化，更新内部列表
    watch(() => props.value, (newVal) => {
      fileList.value = newVal;
    });
    const uploadList = (file) => {
      fileList.value = file
    }
    // 文件大小限制
    const beforeUpload = (file) => {
      console.log('file', file)
      const isLt50M = file.size / 1024 / 1024 < props.size;
      if (!isLt50M) {
        ElMessage.error(`上传文件大小不能超过 ${props.size}MB!`);
      }
      return isLt50M;
    };
    // 上传错误
    const errorList = (error, uploadFile) => {
      ElMessage.error(error.msg ? error.msg : '上传失败')
    }
    // 文件修改
    const changeFile = (res, file, fileList) => {

      if (file.code === 500) {
        ElMessage.error(file.msg)
        return
      }
      if (props.autoUpload && props.limit == 1) {
        let response = fileList[0].response
        if (response.code === 200) {
          emit('update:value', [response]);
        }
      } else if (props.autoUpload && props.limit > 1) {

        if (res.code === 200) {
          emit('update:value', fileList);
        }
      } else {
        nextTick(() => {
          emit('update:value', fileList[0]);
        })
      }
    }
    const fileExceed = (file) => {

      ElMessage.error(`最多只能上传${props.limit}个文件`)
    }
    const fileRemove = (file) => {
      console.log('fileRemove', file)
      // const fileNum:any = fileList.value.indexOf(file)
      // fileList.value.splice(fileNum, 1)
      // emit('update:remove', fileList);
    }
    const fileGet = (file, fileList) => {
      if (!props.autoUpload) {
        if (props.format.length != 0) {
          let lastFileList = [];
          fileList.map((atem) => {
            const nameType = atem.name.split('.')
            const areyouSure = props.format.some((item) => item == nameType[1])
            if (areyouSure) {
              lastFileList.push(atem)
            }
          })
          if(lastFileList.length != fileList.length){
            ElMessage.error(formatValue.value)
          }
          nextTick(() => {
            emit('update:value', lastFileList);
          })
          return
        }

        nextTick(() => {
          emit('update:value', fileList);
        })
      }
    }
    return {
      acceptValue,
      fileList,
      headers,
      formatValue,
      beforeUpload,
      uploadList,
      changeFile,
      errorList,
      fileGet,
      fileExceed,
      fileRemove,
    }
  }
})

</script>
<style lang='postcss'>
.file-style {
  width: 90px;
  height: 90px;
  background: #F2F3F7;
  border-radius: 4px 4px 4px 4px;

  p {
    padding-top: 4px;
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: 400;
    font-size: 12px;
    color: #666666;
  }
}

.img_upload {
  .svg-icon {
    height: 30px;
    width: 30px;
  }

  p {
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: 400;
    font-size: 12px;
    color: #666666;
    line-height: 10px;
  }
}
</style>
