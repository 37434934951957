<template>
  <div>
    <template v-if="!item.children||(item.children&&!item.children.length)">
        <el-menu-item :index="indexKey" @click="navigatorTo(item,indexKey)" v-if="item.showFlag==='1'">
          <span v-html="item.icon" class="svg-icon" :class="indexKey.indexOf('-')>0?'menu_title_er_h1':'menu_title_h1'"
                v-if="item.icon && (mode!=='horizontal'||isIcon)"></span>
          <svg-icon icon-class="kjj" :class="indexKey.indexOf('-')>0?'menu_title_er_h1':'menu_title_h1'"
                    v-if="!item.icon && (mode!=='horizontal'||isIcon)"></svg-icon>
          <template #title>
            <span class="menu_title truncate m_w" :title="item.name"
                  :class="indexKey.indexOf('-')>0?'menu_title_er_h1':'menu_title_h1 '">{{ item.name }}</span>
          </template>

        </el-menu-item>
    </template>
    <el-sub-menu v-else-if="item.showFlag==='1'" ref="subMenu" :index="indexKey" popper-append-to-body>
      <template #title>
        <span v-html="item.icon" :class="indexKey.indexOf('-')>0?'menu_title_er_h1':'menu_title_h1'" class="svg-icon"
              v-if="item.icon && (mode!=='horizontal'||isIcon)"></span>
        <svg-icon icon-class="kjj" :class="indexKey.indexOf('-')>0?'menu_title_er_h1 flex-shrink-0':'menu_title_h1  flex-shrink-0'"
                  v-if="!item.icon && (mode!=='horizontal'||isIcon)"></svg-icon>
        <span class="menu_title truncate menu_title_h1   flex-shrink-0" :title="item.name">{{ item.name }}</span>
      </template>

      <sidebar-item
          v-for="(child,childIndex) in item.children"
          :key="indexKey+'-'+childIndex"
          :index-key="indexKey+'-'+childIndex"
          :item="child"
          :isIcon="true"
          :mode='mode'
      />
    </el-sub-menu>
  </div>
</template>

<script setup>
import {computed} from "vue";
import SidebarItem from './silderItem.vue'
import {ref} from 'vue'
import {useRouter} from "vue-router";
import {ElMessage} from "element-plus";
import {mapMutations, useStore} from "vuex";
import {addUserVisitRecord} from "@/api";
import {addEditableTabs} from "@/utils/tool";

const props = defineProps({
  // route object
  item: {
    type: Object,
    required: true
  },
  mode: {
    type: String,
    default: 'horizontal'
  },
  indexKey: {
    type: Number,
  },
  isNest: {
    type: Boolean,
    default: false
  }, isIcon: {
    type: Boolean,
    default: false
  },
  basePath: {
    type: String,
    default: ''
  }
})

const onlyOneChild = ref({});
const router = useRouter()
const store = useStore();

// 使用多个 computed 引用来访问不同的状态
const appInfo = computed(() => store.state.appInfo);
const orgInfo = computed(() => store.state.orgInfo);

const navigatorTo = (row, key=null) => {
  if (key) {
    sessionStorage.setItem("defaultMenuId", key)
  }
  let to = row.path
  /* 调用接口添加访问记录 */
  let params = {
    appId: appInfo.value.appId,
    modelId: appInfo.value.modelId,
    url: to,
    orgCode: orgInfo.value.orgCode
  }
  addUserVisitRecord(params)
  addEditableTabs(row)
  if (to) {
    sessionStorage.setItem("menuList", JSON.stringify(row))
    router.push(to)
  } else {
    ElMessage.warning("跳转异常，请检查路径是否正确")
  }
}

function hasOneShowingChild(children = [], parent) {
  if (!children) {
    children = [];
  }
  const showingChildren = children.filter(item => {
    if (item.hidden) {
      return false
    } else {
      // Temp set(will be used if only has one showing child)
      onlyOneChild.value = item
      return true
    }
  })

  // When there is only one child router, the child router is displayed by default
  if (showingChildren.length === 1) {
    return true
  }

  // Show parent if there are no child router to display
  if (showingChildren.length === 0) {
    onlyOneChild.value = {...parent, path: '', noShowingChildren: true}
    return true
  }

  return false
};

</script>
<style lang="postcss">
.menuListClass {
  .el-sub-menu {
    //height: 40px;
    //margin: 10px 0;
  }


  .menu_title_h1 {
    color: var(--el-menu-h1-color) !important;
  }

  .menu_title_er_h1 {
    color: var(--el-menu-text-color) !important;
    position: relative;
    flex-shrink: 0;
  }

  .el-menu-item.is-active .menu_title_er_h1 {
    color: var(--el-menu-active-h2-color) !important;
  }


  .el-menu-item {
    //height: 40px;
    //margin: 10px 0;

    .svg-icon {
      display: inline-block;
      height: 20px;
      width: 20px;
      margin-left: 30px;
      margin-right: 8px;
    }

  }


  .el-sub-menu__title {
    .svg-icon {
      display: inline-block;
      height: 20px;
      width: 20px;
      margin-left: 30px;
      margin-right: 30px;
    }
  }

  .el-menu-tooltip__trigger {
    .svg-icon {
      display: inline-block;
      height: 20px;
      width: 20px;
      margin-left: 10px !important;
      margin-right: 0px !important;
    }
  }


}

.menuListClass.el-menu.el-menu--vertical {
  .m_w {
    display: inline-block;
    width: 100px;
  }
}

.menuListClass.el-menu--collapse {
  .svg-icon {
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-left: 10px !important;
    margin-right: 0px !important;

    svg {
      height: 20px;
      width: 20px;
    }
  }
}

.el-popper {
  .el-menu {
    .svg-icon {
      display: inline-block;
      height: 20px;
      width: 20px;
      margin-left: 10px;
      margin-right: 8px;
    }
  }


}

.el-menu--vertical {
  .el-sub-menu__title {
    height: 40px;
    margin: 7px 0;
    line-height: 40px;
  }

  .el-menu-item {
    height: 40px;
    margin: 7px 0;
    line-height: 40px;
  }
}
</style>
