//isExternal
import {ElLoading, ElMessage} from "element-plus";
import {handleThemeStyle} from "@/utils/common";
import request from "@/utils/request";
import store from "@/store";
import {saveAs} from 'file-saver'
import {postBlob} from "@/api";

export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * 时间显示优化 应用于资讯等内容
 * @param {number} timeStr  传入的时间字符串
 * @returns {string} - 相对时间文本
 */

export function getTimeAgoText(timeStr, type) {
    let timestamp = new Date(timeStr).getTime()
    const currentTime = new Date().getTime()
    const timeDifference = Math.abs(currentTime - timestamp) / 1000;

    if (timeDifference < 600) {
        return '刚刚';
    } else if (timeDifference < 3600) {
        const minutes = Math.floor(timeDifference / 60);
        return `${minutes}分钟前`;
    } else if (timeDifference < 86400) {
        const hours = Math.floor(timeDifference / 3600);
        return `${hours}小时前`;
    } else if (timeDifference < 259200) {
        const days = Math.floor(timeDifference / 86400);
        return `${days}天前`;
    } else {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        if (type == 'hh:mm') {
            return `${year}年${month}月${day}日  ${hours}时${minutes}分`
        } else if (type == 'YYYY-MM-DD') {
            return `${year}-${month}-${day}`
        } else {
            return `${year}年${month}月${day}日`
        }
    }
}

// 验证是否为blob格式
export async function blobValidate(data) {
    try {
        const text = await data.text()
        JSON.parse(text)
        return false
    } catch (error) {
        return true
    }
}

let downloadLoadingInstance

export function download(url, params, filename) {
    downloadLoadingInstance = ElLoading.service({
        text: '正在下载数据，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    })
    return request.post(url, params, {
        // transformRequest: [(params) => { return tansParams(params) }],
        // application/x-www-form-urlencoded
        headers: {'Content-Type': 'application/json'},
        responseType: 'blob'
    }).then(async (data) => {
        const isLogin = await blobValidate(data.data)
        if (isLogin) {
            const blob = new Blob([data.data])
            if (data.headers['content-disposition']) {
                filename = decodeURI((data.headers['content-disposition']).split('filename=')[1]).replace(/\"/g, '')
            }
            saveAs(blob, filename)
        }
        downloadLoadingInstance.close()
    })
        .catch((r) => {
            console.error(r)
            ElMessage.error('下载文件出现错误，请联系管理员！')
            downloadLoadingInstance.close()
        })
}


export function downloadget(url, filename, contentType = 'application/json') {
    downloadLoadingInstance = ElLoading.service({
        text: '正在下载数据，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    })
    return request.get(url, {
        // transformRequest: [(params) => { return tansParams(params) }],
        // application/x-www-form-urlencoded
        headers: {'Content-Type': contentType},
        responseType: 'blob'
    }).then(async (data) => {
        const isLogin = await blobValidate(data.data)
        if (isLogin) {
            const blob = new Blob([data.data])
            if (data.headers['content-disposition']) {
                filename = decodeURI((data.headers['content-disposition']).split('filename=')[1]).replace(/\"/g, '')
                if ((data.headers['content-disposition']).split('filename=')[1]) {

                    filename = decodeURI((data.headers['content-disposition']).split('filename=')[1]).replace(/\"/g, '')
                } else {
                    filename = decodeURI((data.headers['content-disposition']).split('filename*=utf-8\'\'')[1]).replace(/\"/g, '')
                }
            }
            console.log('filename', filename)
            saveAs(blob, filename)
        }
        downloadLoadingInstance.close()
    })
        .catch((r) => {
            console.error(r)
            ElMessage.error('下载文件出现错误，请联系管理员！')
            downloadLoadingInstance.close()
        })
}


// 添加页签的公共方法处理

export function addEditableTabs(row) {

    let editableTabs = sessionStorage.getItem("editableTabs") ? JSON.parse(sessionStorage.getItem("editableTabs")) : []
    let obj = {
        title: row.name,
        name: row.path,
        content: row.name,
    }
    console.log(obj)
    // 检查数组中是否已经存在具有相同name的对象
    let existingTabIndex = editableTabs.findIndex(tab => tab.name === obj.name);
    // 如果找到了具有相同name的对象
    if (existingTabIndex !== -1) {
        // 从数组中移除该对象
        let existingTab = editableTabs.splice(existingTabIndex, 1)[0];
        // 更新对象的内容（如果需要）
        existingTab.content = obj.content;
        // 将该对象添加到数组的末尾
        editableTabs.push(existingTab);
    }else{
        editableTabs.push(obj);
    }
    store.commit('setEditableTabs', editableTabs);

}


// 获取当前菜单的默认activeId
export function getActiveId(data, to) {
    if(!data.length){
        return
    }
    // 获取当前的菜单id
    let pathname = to
    function findMenuIndex(menuList, targetPath, parentIndex = '') {
        for (let i = 0; i < menuList.length; i++) {
            const menu = menuList[i];
            if (menu.path === targetPath) {
                return parentIndex === '' ? i.toString() : parentIndex + '-' + i;
            } else if (menu.children) {
                const index = findMenuIndex(menu.children, targetPath, parentIndex === '' ? i.toString() : parentIndex + '-' + i);
                if (index) {
                    return index;
                }
            }
        }
        return null;
    }

    return findMenuIndex(data, pathname);

}



export function downloadBlob(url, filename, contentType = 'application/json') {
    downloadLoadingInstance = ElLoading.service({
        text: '正在下载数据，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    })
    postBlob("/api/file/ossFile/downloadStreamByPreSignedUrl",{
        preSignedUrl: url,
        fileName:filename
    }).then(res=>{

    })


    // return request.post('', {
    //     // transformRequest: [(params) => { return tansParams(params) }],
    //     // application/x-www-form-urlencoded
    //     headers: {'Content-Type': contentType},
    //     responseType: 'blob',
    //     data: {
    //         preSignedUrl: url,
    //         fileName:filename
    //     }
    // }).then(async (data) => {
    //     const isLogin = await blobValidate(data.data)
    //     if (isLogin) {
    //         const blob = new Blob([data.data])
    //         if (data.headers['content-disposition']) {
    //             filename = decodeURI((data.headers['content-disposition']).split('filename=')[1]).replace(/\"/g, '')
    //             if ((data.headers['content-disposition']).split('filename=')[1]) {
    //
    //                 filename = decodeURI((data.headers['content-disposition']).split('filename=')[1]).replace(/\"/g, '')
    //             } else {
    //                 filename = decodeURI((data.headers['content-disposition']).split('filename*=utf-8\'\'')[1]).replace(/\"/g, '')
    //             }
    //         }
    //         console.log('filename', filename)
    //         saveAs(blob, filename)
    //     }
    //     downloadLoadingInstance.close()
    // })
    //     .catch((r) => {
    //         console.error(r)
    //         ElMessage.error('下载文件出现错误，请联系管理员！')
    //         downloadLoadingInstance.close()
    //     })
}
