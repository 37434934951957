<template>
  <div class="loginbj flex flex-center w-screen h-screen">
    <div class="loginContent ">
      <div class="leftfont ">
        <div class="titleImg"></div>
        <div class="fonts">
          <div class="logoSj"></div>
          <div>
            <div class="leftFonts">山西焦煤集团新能源有限公司</div>
            <div class="line"></div>
            <div class="leftFontsE">SHANXI COKING COAL NEW ENERGY CO.,LTD.</div>
          </div>
        </div>
      </div>
      <div class="rightContent">
        <div class="title">用户登录</div>
        <el-form ref='ruleForm' label-position='right' :model='form' :rules='rules'  autocomplete="new-password">
          <el-form-item class='forit forit02 flex' prop='name'>
            <el-input v-model='form.name' autocomplete="off"  class="userinp" placeholder='请输入用户名'>
              <template #prefix>
                <div class="prefixUser"></div>
                <div class="prefixUserRight"></div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item class='forit1 forit02 flex ' prop='pwd'>
            <el-input v-model='form.pwd'  autocomplete="new-password"  show-password class="userinp" placeholder='请输入密码'>
              <template #prefix>
                <div class="prefixPass"></div>
                <div class="prefixPassRight"></div>
              </template>
            </el-input>
          </el-form-item>
<!--          <el-form-item prop='checked' class='f'>-->
<!--            <div style='width: 100%;' class='flex justify-between'>-->
<!--              <span style='color:#B3B3B3' class="cursor-pointer" @click='forget'>忘记密码？</span>-->
<!--              <span class='span_primary cursor-pointer' @click='register'>立即注册</span>-->
<!--            </div>-->
<!--          </el-form-item>-->
          <el-form-item>
            <el-button type='primary' class='subm' @click='onSubmit'>登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- <div class="bottomInfo">
      山西合邦电力科技有限公司版权所有
    </div> -->
  </div>

</template>

<script setup>
  import { defineComponent, reactive, ref } from 'vue'
  import { ElMessage } from 'element-plus'
  import { validate, setLocal } from '../utils/formExtend'
  import { mapMutations } from 'vuex'
  import router from '@/router/index'
  import { login } from '@/api/index'
  import '@/utils/registerMicroAppsConfig'
  import { defineEmits } from 'vue'
  import JSEncrypt from 'jsencrypt';
  // 忘记密码
  const forget = () => {
    router.push("/retrievePassword")
    emit("retrievePassword")
  }
  // 注册
  const register = () => {
    router.push("/register")
    emit("register")
  }
  const emit = defineEmits(['isSuccess'])
  const storeMutation = mapMutations(['SET_ACCESSTOKEN']);
  let form = reactive({
    name: '',
    pwd: '',
    owner: 'pta'
  })
  const loginApi = (param) => {
    login(param).then(res => {
      ElMessage.success('登录成功')
      const token = res.data.token
      setLocal('token', token, 1000 * 60 * 60)
      // 告诉父元素登录成功了
      emit('isSuccess', 'login')
      //router.push(router.currentRoute.value.query.f)


    }).catch(error => {

    })

  }
  const ruleForm = ref(null)
  const enterSubmit = (e) => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }
  const onSubmit = async () => {
    let { name, pwd } = form
    if (!await validate(ruleForm)) return
    const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCk2nvbv1V92I7wodOVGhAezEHrRHsPj3XQRzLEUYzhkXSqTAVDJrC3fS8c8bz2NDIROr5XzOmgTVrDIFL/kYSjc79Q+65684pYMN2R+pZKplB8hlUhJ5LscrES43TquLM6Oycp98D64tqtGm/XIURoi4b9iDQpVFNFqk3O1K919wIDAQAB';
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    const encName = encrypt.encrypt(name);
    const encPassword = encrypt.encrypt(pwd);
    sessionStorage.setItem('name',name)
    await loginApi({ username: encName, password: encPassword, owner: 'pta' })
    // await loginApi({username: name, password: pwd, owner: 'pta'})

  }
  const rules = reactive({
    name: [
      {
        validator: (rule, value, callback) => {
          if (!value) {
            return callback(new Error('用户名不能为空'))
          }
          callback()
        }, trigger: 'blur'
      }
    ],
    pwd: [
      {
        validator: (rule, value, callback) => {
          if (!value) {
            return callback(new Error('密码不能为空'))
          }
          callback()
        }, trigger: 'blur'
      }
    ]
  })

</script>

<style lang='less' scoped>
  .flex-center {
    align-items: center;
    justify-content: center;
  }

  .loginbj {
    width: 100%;
    height: 100vh;
    background-image: url('../assets/img/loginbj_sj.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;

    .loginContent {
      width: 100%;
      height: 277px;
      background: rgba(255, 255, 255, 0.17);
      display: flex;

      .leftfont {
        margin-left: 18%;

        .titleImg {
          width: 595px;
          height: 53px;
          background-image: url('../assets/img/title_sj.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          margin-top: 48px;
          margin-bottom: 26px;
        }

        .fonts {
          display: flex;
          padding: 15px;
          background-color: rgba(0, 51, 122, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.17);

          .logoSj {
            width: 81px;
            height: 81px;
            background-image: url('../assets/img/logoSJ2.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            margin-right: 15px;
          }

          .leftFonts {
            height: 36px;
            font-size: 36px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 36px;
            text-shadow: 0px 2px 3px #013667;
            margin-bottom: 12px;
          }

          .line {
            height: 2px;
            background: #FFFFFF;
            opacity: 0.25;
          }

          .leftFontsE {
            height: 18px;
            font-size: 21px;
            font-family: Arial;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 47px;
            text-shadow: 0px 2px 3px #013667;
          }
        }
      }

      .rightContent {
        width: 383px;
        height: 364px;
        background: rgba(255, 255, 255, 0.91);
        border: 1px solid #1A8BFF;
        border-radius: 10px;
        margin-top: -50px;
        margin-left: 170px;
        padding: 36px 33px 40px 33px;
        text-align: center;

        .title {
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          text-align: center;
          margin-bottom: 30px;
        }

        .forit {
          height: 44px;
          padding: 10;
          box-sizing: border-box;
          margin: 0 auto 40px;
          border: 1px solid #DCDCDC;
          border-radius: 4px;

          .el-input {
            width: 100%;
            height: 100%;
          }

          ::v-deep(.el-input__inner) {
            width: 100%;
            height: 100%;
            border: none !important;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 300;
            color: #333333 !important;
          }

          ::v-deep(.el-form-item__error) {
            top: 56px;
            left: 20px;
          }

          ::v-deep(.el-input__wrapper) {
            box-shadow: none;
            width: 100%;
            height: 100%;
            border: none;
          }

          .userinp {
            width: 100%;
            height: 90%;

            .prefixUser {
              width: 24px;
              height: 24px;
              margin-right: 10px;
              background-image: url('../assets/img/userIcon_sj.png');
              background-repeat: no-repeat;
            }

            .prefixUserRight {
              width: 2px;
              height: 16px;
              background: #1890FF;
              margin-right: 16px;
            }

            .prefixPass {
              width: 24px;
              height: 24px;
              margin-right: 10px;
              background-image: url('../assets/img/passwordIcon_sj.png');
              background-repeat: no-repeat;
            }

            .prefixPassRight {
              width: 2px;
              height: 16px;
              background: #1890FF;
              margin-right: 16px;
            }
          }

          .code {
            width: 30%;
            text-align: right;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #3381FF;
            line-height: 79px;
            cursor: pointer;
            letter-spacing: 1px;
          }
        }
        .forit1 {
          height: 44px;
          padding: 10;
          box-sizing: border-box;
          margin: 0 auto 15px;
          border: 1px solid #DCDCDC;
          border-radius: 4px;

          .el-input {
            width: 100%;
            height: 100%;
          }

          ::v-deep(.el-input__inner) {
            width: 100%;
            height: 100%;
            border: none !important;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 300;
            color: #333333 !important;
          }

          ::v-deep(.el-form-item__error) {
            top: 56px;
            left: 20px;
          }

          ::v-deep(.el-input__wrapper) {
            box-shadow: none;
            width: 100%;
            height: 100%;
            border: none;
          }

          .userinp {
            width: 100%;
            height: 90%;

            .prefixUser {
              width: 24px;
              height: 24px;
              margin-right: 10px;
              background-image: url('../assets/img/userIcon_sj.png');
              background-repeat: no-repeat;
            }

            .prefixUserRight {
              width: 2px;
              height: 16px;
              background: #1890FF;
              margin-right: 16px;
            }

            .prefixPass {
              width: 24px;
              height: 24px;
              margin-right: 10px;
              background-image: url('../assets/img/passwordIcon_sj.png');
              background-repeat: no-repeat;
            }

            .prefixPassRight {
              width: 2px;
              height: 16px;
              background: #1890FF;
              margin-right: 16px;
            }
          }

          .code {
            width: 30%;
            text-align: right;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #3381FF;
            line-height: 79px;
            cursor: pointer;
            letter-spacing: 1px;
          }
        }
        .forit02 {
          ::v-deep(.el-form-item__error) {
            top: 45px;
            left: 20px;
          }
        }

        .subm {
          width: 100%;
          height: 44px;
          background: #1890FF;
          border-radius: 4px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 33px;
          margin: 0 auto 0;
          text-align: center;
          display: flex;
          justify-content: center;
        }

        .checkContent {
          width: 495px;
          margin: 70px auto 30px;
          text-align: left;
        }
      }
    }

    .bottomInfo {
      height: 16px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #E7FBFF;
      line-height: 30px;
      opacity: 0.74;
      position: absolute;
      bottom: 44px;
      left: auto;
      right: auto;
    }
  }
</style>
