import {createStore} from 'vuex';

// import Vue from 'vue';         // CDN引入vue.js  注释此行
// import Vuex from 'vuex';       // CDN引入vuex.js  注释此行
// Vue.use(Vuex);                 // CDN引入无需启用

const state = {
    accessToken: '',
    noiceNumber: sessionStorage.getItem("noiceNumber") ? sessionStorage.getItem("noiceNumber") : 0,
    userName: '',
    userInfo: sessionStorage.getItem("userInfo") ? JSON.parse(sessionStorage.getItem("userInfo")) : {},
    orgInfo: sessionStorage.getItem("ORGINFO") ? JSON.parse(sessionStorage.getItem("ORGINFO")) : {},
    appInfo: sessionStorage.getItem("APPINFO") ? JSON.parse(sessionStorage.getItem("APPINFO")) : {},
    breadcrumbList: [],
    funList: sessionStorage.getItem("funList") ? JSON.parse(sessionStorage.getItem("funList")) : [],                // 启用的功能列表
    menulistdic: sessionStorage.getItem("menulistdic") ? JSON.parse(sessionStorage.getItem("menulistdic")) : {},                // 启用的功能列表
    menuId: JSON.parse(sessionStorage.getItem("SET_MENUID_SESSION")) || '',                  // 选中状态的menuId
    menuGroup: JSON.parse(sessionStorage.getItem("SET_MENUID_SESSION_GROUP")) || [],
    headerImg: sessionStorage.getItem("HEADERIMG") || '',
    editableTabs: sessionStorage.getItem("editableTabs") ? JSON.parse(sessionStorage.getItem("editableTabs")) : [],
};

const getters = {};
const actions = {};
const mutations = {
    // 设置headerImg
    SET_HEADERIMG: (state, img) => {
        state.headerImg = img;
        sessionStorage.setItem("HEADERIMG", img)
    },
    // 修改页签相关信息
    setEditableTabs(state, data) {
        // 这里判断一下是单页签还是多页签
        let topic = sessionStorage.getItem("topic") ? JSON.parse(sessionStorage.getItem("topic")) : null
        let tabsViewType = topic ? topic.tabsViewType : "2"
        if (tabsViewType === '1') {
            state.editableTabs = data[data.length - 1] ? [data[data.length - 1]] : []
        } else {
            state.editableTabs = data
        }
        if (state.editableTabs && state.editableTabs.length
        ) {
            sessionStorage.setItem("editableTabs", JSON.stringify(state.editableTabs))
        }


    },
// 设置用户管理
    SET_USERMANAGE: (state, data) => {
        sessionStorage.setItem("userInfo", JSON.stringify(data))
        state.userInfo = data;
    },
    // 设置用户管理
    SET_NOICENUMBER:
        (state, data) => {
            sessionStorage.setItem("noiceNumber", data)
            state.noiceNumber = data;
        },
    // 设置回退路由
    SET_MENULISTDIC:
        (state, data) => {
            sessionStorage.setItem("menulistdic", JSON.stringify(data))
            state.menulistdic = data;
        },
    // 设置选择组织相关信息
    SET_ORGINFO:
        (state, data) => {
            if (data) {
                sessionStorage.setItem("ORGINFO", JSON.stringify(data))
                state.orgInfo = data;
            }

        },
    // 设置应用相关信息
    SET_APPINFO:
        (state, data) => {
            sessionStorage.setItem("APPINFO", JSON.stringify(data))
            state.appInfo = data;
        },

    // 设置accessToken
    SET_ACCESSTOKEN:
        (state, token) => {
            state.accessToken = token;
        },
    // 选中状态的menuId
    SET_MENUID:
        (state, id) => {
            state.menuId = id;
            sessionStorage.setItem("SET_MENUID_SESSION", JSON.stringify(id))
        },
    // 选中状态的menuId
    SET_MENUGROUP:
        (state, data) => {
            state.menuGroup = data;
            sessionStorage.setItem("SET_MENUID_SESSION_GROUP", JSON.stringify(data))
        },
    // 设置userName
    SET_USERNAME:
        (state, userName) => {
            state.userName = userName;
        },

    // 设置breadcrumbList面包屑
    SET_BREADCRUMB:
        (state, list) => {
            state.breadcrumbList = list.map(val => val);
        },

    // 设置当前用户可用的功能列表
    SET_FUNLIST:
        (state, list) => {
            state.funList = list.map(val => val);
            sessionStorage.setItem("funList", JSON.stringify(state.funList))
        },

}

export default createStore({
    state,
    getters,
    actions,
    mutations,
    modules: {}
})
