import login from '@/views/login';
import loginhb from '@/views/User/login01.vue';
import registerPage from '@/views/User/registerPage';
import registerSuccess from '@/views/User/registerSuccess';
import retrievePassword from '@/views/User/retrievePassword';
import reviewProgress from '@/views/User/reviewProgress';
import userInfo from '@/views/User/userInfo';
import notice from '@/views/User/notice';
import informationList from '@/views/User/informationList';
import noticeDetails from '@/views/User/notice/details.vue';
import informationDetails from '@/views/User/information/details.vue';
import additionInfo from '@/views/User/additionalInfo.vue';
import mainHome from '@/views/User/qiankunMenu.vue';
import home from '@/views/User/Home.vue';
import login_SJ from '@/views/login_SJ.vue';
// import page404 from '@/views/login_SJ.vue';
import _ from 'lodash'
import {createRouter, createWebHistory} from 'vue-router'
import {appsData, dynamicRegisterApps} from "@/utils/registerMicroAppsConfig";
import {removeLocaStorage} from "@/utils/common";
import {
    getApi,
} from '@/api'
import store from "@/store";
import {addEditableTabs} from "@/utils/tool";
// import { next } from 'cheerio/lib/api/traversing';
const rData = sessionStorage.getItem("routerList") ? JSON.parse(sessionStorage.getItem("routerList")) : []
//路由数组
export const routes = [
    {path: '/', name: 'home', component: mainHome},
    {path: '/app', name: 'home', component: home},
    {path: '/login', name: 'login', component: loginhb},
    {path: '/registerPage', name: 'registerPage', component: registerPage},
    {path: '/registerSuccess', name: 'registerSuccess', component: registerSuccess},
    {path: '/reviewProgress', name: 'reviewProgress', component: reviewProgress},
    {path: '/additionInfo', name: 'register', component: additionInfo},
    {path: '/retrievePassword', name: 'retrievePassword', component: retrievePassword},
    {path: '/userInfo', name: 'userInfo', component: userInfo},
    {path: '/notice', name: 'notice', component: notice},
    {path: '/informationList', name: 'informationList', component: informationList},
    {path: '/noticeDetails', name: 'noticeDetails', component: noticeDetails},
    {path: '/informationDetails', name: 'informationDetails', component: informationDetails},
    {path: '/loginsj', name: 'loginsj', component: login_SJ},
    {
        path: '/403',
        component: () => import('@/views/error/403'),
        hidden: true
    },
    {
        path: '/404',
        component: () => import('@/views/error/404'),
        hidden: true
    },
    // {path: '/404', name: 'page404', component: page404},
    /* 配置各种应用*/
    // { path: '/holdKanban', name: 'Home', app: 'holdKanban' },
    // { path: '/planReview', name: 'About', app: 'planReview' }
]

//路由对象
const router = createRouter({
    history: createWebHistory(),
    routes //上面的路由数组
})

function checkPathExists(menuList, targetPath) {
    for (let item of menuList) {
        if (item.path === targetPath) {
            return true;
        }
        if (item.childrenMenuList) {
            if (checkPathExists(item.childrenMenuList, targetPath)) {
                return true;
            }
        }
    }
    return false;
}

// router.beforeEach(async (to, from, next) => {
//     if (_.isEmpty(history.state.current)) {
//         _.assign(history.state, {current: from.fullPath});
//     }
//     let routerList = JSON.parse(sessionStorage.getItem("routerList"))
//     if (!checkPathExists(routerList,to.fullPath)&&to.fullPath!=='/'&&to.fullPath!=='/userInfo') {
//         // 路由无效，重定向到 404 页面
//         next('/404')
//     } else {
//         next()
//     }
//
// })
function findNodeByPath(node, targetPath) {
    // 检查当前节点的path是否与目标path匹配
    if (node.path === targetPath) {

        return node;
    }

    // 如果当前节点有子节点，则递归搜索每个子节点
    if (node.children) {
        for (let child of node.children) {
            let result = findNodeByPath(child, targetPath);
            // 如果找到了匹配的节点，返回它
            if (result) {
                return result;
            }
        }
    }

    // 如果当前节点和子节点都没有匹配的，返回null
    return null;
}

let noToken = ['/registerSuccess', '/registerPage', '/retrievePassword', '/login', '/reviewProgress']

function extractPathWithType(str) {
    // 正则表达式用于匹配路径和type参数
    let regex = /[\?&]type=([^&]*)/;
    let match = str.match(regex);
    // 如果匹配成功，则返回问号前和type的内容
    if (match) {
        // 提取问号前的部分
        let basePath = str.split('?')[0];
        // 提取 'type' 参数的内容
        let typeValue = match[1];
        return basePath + '?type=' + typeValue;
    }

    // 如果没有匹配到type参数，返回空字符串或者null
    return str.split('?')[0];
}

router.beforeEach(async (to, from, next) => {
    // 在跳转前将当前路由添加到历史记录中
    let routerCen = []
    let whiteRouter = routes.map(item => item.path)
    let whiteMenu = JSON.parse(sessionStorage.getItem('whiteMenu')) ? JSON.parse(sessionStorage.getItem('whiteMenu')) : []
    // 后端定义的要求白名单 ：路由白名单：功能管理、应用模板管理所有页面、组织列表及详情
    let whiteAdmin = [
        '/application/application/v2.0/system/templateApplication',
        '/application/application/v2.0/system/templateApplicationDetails',
        '/application/application/v2.0/system/templateApplicationAdd',
        '/application/application/v2.0/system/templateApplicationAdd?type=edit',
        '/application/application/v2.0/operationManage/organization',
        '/application/application/v2.0/operationManage/details',
        '/application/application/v2.0/operationManage/functionLIst',
    ]
    if (whiteRouter.includes(to.path) || whiteAdmin.includes(to.path) || whiteMenu.includes(to.path) || process.env.NODE_ENV === "development" || to.path.indexOf('/login/') !== -1) {
        // 查找path为'path'的节点
        const targetNode = findNodeByPath({children: rData}, extractPathWithType(to.fullPath));
        if (targetNode && targetNode.showFlag === '1') {
            // 添加页签
            addEditableTabs(
                {
                    name: targetNode.name,
                    path: to.fullPath
                }
            )
        }

        // 判断当前是否在不需要登录的白名单页面
        if (noToken.includes(to.path)) {
            next()
        }

        // 判断是否登录
        if (!sessionStorage.getItem('token') && !noToken.includes(to.path)) {
            if (to.path.includes("/login/")) {
                next("/login?path=" + to.path)
            }
            removeLocaStorage()
            next("/login")
        }

        let pathname = location.pathname;
        if (pathname == "/app" || noToken.includes(to.path) || pathname == '/userInfo' || pathname == '/notice' || pathname == '/informationList') {
            next()
        } else if (to.path === '/') {
            next('/app')
        } else {
            let url = '/' + pathname.split('/')[1];
            let arr = appsData.filter((item) => {
                return item.activeRule === url;
            });
            dynamicRegisterApps(arr);
            // next();
        }
        if (_.isEmpty(history.state.current)) {
            _.assign(history.state, {current: from.fullPath});
        }
        next()
    } else {
        try {
            const res = await getApi("/app/workbench/routeAuth", {url: extractPathWithType(to.fullPath)});
            if (res.code === 200) {
                // 判断当前是否在不需要登录的白名单页面
                if (noToken.includes(to.path)) {
                    return next();
                }

                // 判断是否登录
                if (!sessionStorage.getItem('token') && !noToken.includes(to.path)) {
                    if (to.path.includes("/login/")) {
                        return next("/login?path=" + to.path);
                    }
                    removeLocaStorage();
                    return next("/login");
                }

                let pathname = location.pathname;
                if (pathname === "/app" || noToken.includes(to.path) || pathname === '/userInfo' || pathname === '/notice' || pathname === '/informationList') {
                    return next();
                } else if (to.path === '/') {
                    return next('/app');
                } else {
                    let url = '/' + pathname.split('/')[1];
                    let arr = appsData.filter((item) => {
                        return item.activeRule === url;
                    });
                    dynamicRegisterApps(arr);
                }

                // 确保只在最后调用一次 next()
                if (_.isEmpty(history.state.current)) {
                    _.assign(history.state, {current: from.fullPath});
                }
                return next();
            }
        } catch (err) {
            // 处理错误，例如：重定向到错误页面
            return next('/403');
        }
    }


})

//导出路由对象，在main.js中引用
export default router
