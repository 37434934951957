<template>
  <div class="notice_details">
    <div class="right bg-white">
      <h5 class="p20">通知详情</h5>
      <div class="flex justify-between no_detials_title_box">
        <div>
          <p class="p_h_1">{{ info.noticeTitle }}</p>
          <p class="p_h_2 m_b_20">发布于：{{ info.releaseTime }}</p>
        </div>
        <div class="flex flex-col items-center">
          <img src="../../../assets/img/home.jpg" v-if="!info.dictClass" alt="" class="pic_icon m_r_20">
          <div v-if="info.dictClass" v-html="info.dictClass" class="pic_icon m_r_20"></div>
          <p class="no_p_1">
            {{ info.noticeType }}
          </p>
        </div>
      </div>
      <div class="list ">
        <el-scrollbar>
          <div class="list_item">
            <p>{{ info.noticeContent }} </p>
          </div>
        </el-scrollbar>

      </div>

      <div class="flex justify-end pagination">
        <el-button type="primary" @click="jumpPage" v-if="info.noticeFunctionPath && info.whetherButton==1">
          {{ info.jumpUrl }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {getUserInfo, updatePersonUserInfo, getUserInfoByToken} from "@/api/UserInfo"
import {mapMutations, mapState} from "vuex";
import editInfo from "@/views/User/editPersonalInfo/component/editInfo.vue";
import {getActiveId, getTimeAgoText} from "@/utils/tool";
import dicTag from "@/components/DicTag/index.vue"
import {addUserVisitRecord, getApi, getAppQuickFuncListApi, getMenuPrv, noticeCount} from "@/api";
import {Search} from "@element-plus/icons-vue";

export default {
  name: "userInfo",
  data() {
    return {
      info: {},
    }
  },
  components: {
    editInfo, dicTag
  },
  computed: {
    ...mapState([
      "userInfo",
      "orgInfo",
      "appInfo",
      'funList',
    ]),
  },
  methods: {
    ...mapMutations(['SET_USERMANAGE', "SET_NOICENUMBER", 'SET_APPINFO', 'SET_NOICENUMBER', 'SET_FUNLIST', 'setEditableTabs']),
    // 页面跳转
    jumpPage() {
      getApi("/app/workbench/routeAuth", {url: this.info.noticeFunctionPath}).then(res => {
        if (res.code === 200) {
          let appInfo = res.data
          this.isApp(appInfo, this.info.noticeFunctionPath)
        } else {
          this.$router.push({
            path: `/${res.code}`
          })
        }
      })

    },
    updateNoice() {
      let params = {
        orgCode: this.orgInfo.orgCode,
        appId: this.appInfo.appId,
      }
      noticeCount(params).then(res => {
        if (res.code === 200) {
          let data = res.data || 0
          this.SET_NOICENUMBER(data);
        }
      })
    },
    // 选择app
    isApp(row, pathUrl) {
      let appId = this.appInfo.appId || ''
      let isUplad = appId !== row.appId
      if (isUplad) {
        sessionStorage.setItem('appid', row.appId)
        sessionStorage.setItem('appRow', JSON.stringify(row))
        this.SET_APPINFO(row)
        // 设置主题
        if (row.topic) {
          sessionStorage.setItem("topic", JSON.stringify(row.topic))
        } else {
          sessionStorage.removeItem("topic")
        }
        // 获取用户选择应用的快捷功能
        this.getAppQuickFuncList(row.appId)
      }
      getMenuPrv(row.appId).then(res => {
        if (!res.data) {
          this.$message.warning("暂时查询不到相关菜单")
          return
        }
        // updateTopic()
        this.updateNoice()
        let data = this.addLevelAndParentPath(res.data)
        this.menuList = this.addLevelAndParentPath(data)
        //计算路由白名单
        let whiteMenu = this.extractPaths(res.data)
        sessionStorage.setItem('whiteMenu', JSON.stringify(whiteMenu))
        sessionStorage.setItem('routerList', JSON.stringify(data))
        let first = this.findRoutePathRow(data, pathUrl)
        let to = first.path
        getActiveId(data, to)
        sessionStorage.setItem("defaultMenuId", this.defaultMenuId)
        /* 调用接口添加访问记录 */
        let params = {
          appId: row.appId,
          modelId: row.modelId,
          url: to,
          orgCode: this.orgInfo.orgCode
        }
        addUserVisitRecord(params).then(res => {
        })
        let editableTabs = []
        let obj = {
          title: first.name,
          name: first.path,
          content: first.name,
        }
        if (!isUplad) {
          editableTabs = sessionStorage.getItem("editableTabs") ? JSON.parse(sessionStorage.getItem("editableTabs")) : []
          // 检查数组中是否已经存在具有相同name的对象
          let existingTab = editableTabs.find(tab => tab.name === obj.name);
          // 如果不存在，则添加到数组中
          if (!existingTab) {
            // 如果数组长度超过6，删除第一个元素
            // if (editableTabs.length >= 6) {
            //   editableTabs.shift(); // 删除数组的第一个元素
            // }
            editableTabs.push(obj);
          }
        } else {
          let editableTabs = []
          editableTabs.push(obj);
        }
        this.setEditableTabs(editableTabs)
        this.$router.push({
          path: pathUrl
        })
      })
    },
    getAppQuickFuncList(id) {
      getAppQuickFuncListApi(id).then((res => {
        this.SET_FUNLIST(res.data || [])
      }))
    },
    getInfo() {
      // 根据 根据token获取用户名
      getApi("/personal/center/notice/detail", {noticeId: this.$route.query.id}).then(res => {
        if (res.code === 200) {
          this.info = res.data
          this.getNoticeCount()
        }
      })
    },
    getNoticeCount() {
      let params = {
        orgCode: this.orgInfo.orgCode,
        appId: this.appInfo.appId,
      }
      // 刷新 用户未读数量
      noticeCount(params).then(res => {
        if (res.code === 200) {
          let data = res.data
          this.SET_NOICENUMBER(data);
        }
      })
    },
    // 使用这个函数并传入树的根节点

    addLevelAndParentPath(items, parentPath = '') {
      return items.map((item) => {
        let newItem = {...item}; // 深拷贝
        newItem.parentPath = parentPath; // 设置上级路径
        if (parentPath === '') { // 如果上级路径是第一层，则不添加 /
          newItem.parentPath = item.name;
          // newItem.level = 0; // 计算层级
        } else {
          newItem.parentPath = `${parentPath}/${item.name}`;
        }
        newItem.level = newItem.parentPath.split('/').length - 1; // 计算层级
        if (item.children && item.children.length > 0) {
          newItem.children = this.addLevelAndParentPath(item.children, newItem.parentPath);
        }
        return newItem;
      });
    },
    extractPaths(menuItems, paths = []) {
      menuItems.forEach(item => {
        if (item.path) {
          paths.push(item.path);
        }
        if (item.children) {
          this.extractPaths(item.children, paths);
        }
      });
      return paths;
    },
    findFirstPath(data) {
      // 遍历数据数组
      for (let i = 0; i < data.length; i++) {
        let result = this.findPath(data[i]);
        // 如果找到了路径，返回它
        if (result) {
          sessionStorage.setItem("menuList", JSON.stringify(data[i]))
          return result;
        }
      }
      // 如果没有找到任何路径，返回undefined
      return undefined;
    },
    findRoutePathRow(routes, targetPath, currentRow = 0) {
      for (let i = 0; i < routes.length; i++) {
        // 如果找到了目标路径，返回当前行
        if (routes[i].path === targetPath) {
          return routes[i];
        }
        // 如果当前路由有子路由，递归查找
        if (routes[i].children && routes[i].children.length > 0) {
          const childRow = this.findRoutePathRow(routes[i].children, targetPath, currentRow + 1);
          // 如果在子路由中找到了，返回子路由中的行
          if (childRow !== null) {
            return {};
          }
        }
      }
      // 如果没有找到，返回null
      return null;
    }

  },
  mounted() {
    this.getInfo()

  }
}
</script>

<style scoped lang="less">
.notice_details {
  margin: 0 auto;
  position: relative;
  height: 100%;
  width: 100%;

  .no_detials_title_box {
    padding: 0px 20px;
    border-bottom: 1px solid #E6E6E6;

    .p_h_1 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      font-size: 14px;
      color: #020C33;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-bottom: 10px;
    }

    .p_h_2 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #8C8C8C;
      text-stroke: 1px rgba(0, 0, 0, 0);
      text-align: left;
      font-style: normal;
      text-transform: none;
      -webkit-text-stroke: 1px rgba(0, 0, 0, 0);

    }

    .no_p_1 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #8C8C8C;
      text-stroke: 1px rgba(0, 0, 0, 0);
      text-align: left;
      font-style: normal;
      text-transform: none;
      -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    }
  }

  h5 {
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    text-stroke: 1px rgba(0, 0, 0, 0);
    text-align: left;
    font-style: normal;
    text-transform: none;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  }

  .right {
    width: 52%;
    height: 100%;
    box-shadow: 0px 2px 6px 1px rgba(3, 75, 143, 0.1608);
    position: absolute;
    left: 50%;
    margin-left: -25%;

    .pic_icon {
      display: inline-block;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      flex-shrink: 0;
    }

    .no_center {
      width: 70%;
    }

    .no_right {
      width: 100px;
      text-align: right;
    }

    .list {
      padding: 20px;
      height: calc(100% - 120px);
      overflow: hidden;

      .list_item {
        margin-bottom: 30px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #020C33;
        line-height: 30px;
        text-stroke: 1px rgba(0, 0, 0, 0);
        text-align: left;
        font-style: normal;
        text-transform: none;
        -webkit-text-stroke: 1px rgba(0, 0, 0, 0);

        p {
          white-space: pre-wrap;
        }
      }
    }

    .pagination {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
  }
}
</style>
